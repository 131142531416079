import {
    Container,
    CssBaseline,
    Divider,
    Grid,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { Contact } from "Contact";
import { HomeImage } from "HomeImage";
import { Library } from "Library";
import { Partners } from "Partners";
import { Helmet } from "react-helmet";
import { theme } from "theme";
import { Align } from "ui/Align";
import { Link } from "ui/Link";
import { Footer } from "../Footer";
import { Header } from "../Header";
import React from "react";

export default function Index() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Helmet
                htmlAttributes={{
                    lang: "en",
                }}
            >
                <meta charSet="utf-8" />
                <title>
                    QPED: Quality-focussed Programming Education: Intellectual
                    Outputs
                </title>
                <meta
                    name="description"
                    content="QPED: Quality-focussed Programming Education - Intellectual Outputs"
                />
                <link rel="canonical" href="https://www.qped.eu" />
            </Helmet>
            <Header />
            <HomeImage title="Intellectual Outputs" />
            {/* Hero unit */}
            <Container
                id="about"
                maxWidth="md"
                component="main"
                sx={{
                    paddingTop: 8,
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingBottom: 6,
                }}
            >
                <Typography color="textSecondary" gutterBottom>
                    The Erasmus+ Project QPED (Quality-focused Programming
                    Education) will create new course material and a toolkit to
                    foster a quality-awareness of students that encloses active
                    thinking about readable and robust code and functional
                    correctness demonstrated by tests for example in relation to
                    specifications.{" "}
                </Typography>
                <Typography color="textSecondary">
                    The QPED project is made up of four Intellectual Outputs
                    (IOs) which all deliver their own results but which also
                    have strong ties. The objective of IO1 is to deliver a
                    blueprint of how to include testing in an early stage of
                    programming education. Here the TILE approach (Test Informed
                    Learning with Examples) is developed that implicitly teaches
                    testing by providing a certain teaching style, sample
                    lecture slides and assignments that stimulate students to
                    test their code, e.g., by demonstrating testing subliminally
                    in lectures or pointing out meaningful test data. In IO2 a
                    procedural guidance is developed that can be applied when
                    developing and testing is taught more explicitly. The
                    developed procedure explains how to arrive at well tested
                    code starting from an informal specification of the required
                    functionality, and it is accompanied by exemplified lecture
                    notes and fully worked-out sample assignments. The concepts
                    of the TILE approach related to arriving at meaningful test
                    cases can also be found in certain steps of the procedural
                    guidance. IO3 is concerned with developing tools to
                    automatically generate feedback regarding the quality of
                    student solutions. This includes the correctness and
                    completeness of the solution (an indicator of how well it
                    was tested) as well as a direct assessment of the tests
                    written by students. The feedback tools developed here can
                    be especially used for the sample assignments developed in
                    IO1 and IO2 and are particularly suitable to check for the
                    learning objectives of the TILE approach and the procedural
                    guidance. Finally, IO4 evaluates the QPED project as a
                    whole. In this IO, existing university courses are adapted
                    to include the results of all three IOs. Furthermore,
                    measurement tools such as diagnostic assignments, rubrics
                    and questionnaires are developed to assess the students'
                    level regarding the learning objectives of the different IOs
                    and the QPED project as a whole, and to compare these levels
                    before and after the material and tools developed in the IOs
                    have been used in the respective courses.
                </Typography>
                <Typography variant="h4" align="center" gutterBottom>
                    Intellectual Outputs
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                    <ul>
                        <li>
                            <a href="/io1">Intellectual Output 1: TILE</a>
                        </li>
                        <li>
                            <a href="/io2">
                                Intellectual Output 2: Procedural Guidance
                            </a>
                        </li>
                        <li>
                            <a href="/io3">
                                Intellectual Output 3: Feedback Tools
                            </a>
                        </li>
                        <li>
                            <a href="/io4">Intellectual Output 4: Evaluation</a>
                        </li>
                    </ul>
                </Typography>
            </Container>
            <Footer />
        </ThemeProvider>
    );
}
